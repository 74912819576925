@import './_variables.scss';
* {
    margin: 0;
    padding: 0;
}

.App {
    overflow: hidden;
}

::-webkit-scrollbar {
    width: 10px;
}
::-webkit-scrollbar-thumb {
    background: lighten($color: $secondary, $amount: 10%);
}
::-webkit-scrollbar-track {
    background: rgb(219, 219, 219);
}

@keyframes hover {
    from {
        margin-top: .5em;
    }
    to {
        margin-top: -.5em;
    }
}
.division-container {
    border: 1px solid white;
    height: 50px;
    margin: 0;
}
.division {
    background: $main;
    min-width: 300px;
    width: 40%;
    margin: 0 auto;
    color: white;
    z-index: 2;
    border-radius: 5px;
    padding: .2em 0;

    animation-name: hover;
    animation-duration: 1s;
    animation-iteration-count: infinite;
    animation-timing-function:linear;
    animation-direction: alternate;

    text-align: center;
    font-family: Poppins;
}
#wave-canvas {
    position: fixed;
    bottom: 0;
}