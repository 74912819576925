@import '../../_variables.scss';

#contact-section {
    text-align: center;
    #notice {
      width: 50%;
      min-width: 200px;
      margin: auto;
      padding: 1em;
      font-size: 120%;
      color: white;
      display: none;
      background: white;
      font-family: Poppins;
    }
    .error {background: #e81e25 !important;}
    .success {background: #1ee886 !important;}
  
    .contact-container {
      margin: auto;
      margin-bottom: 2em;
      p {
        padding: .5em;
        display: block;
        max-width: 650px;
        line-height: 1.5em;
        margin: 2em auto;
        font-family: 'roboto', 'Helvetica';
        font-size: 120%;
      }
    }
  }
  form {
    width: 60%;
    margin: 4em auto;
    max-width: 35rem;
    text-align: center;
    button {
      font-family: Poppins;
      font-size: 90%;
      display: inline-block;
      color: white;
      background: $secondary;
      width: 7rem;
      padding: .5em;
      border: none;
      transition: all .2s;
      border-radius: 5px;
      &:hover {
        cursor: pointer;
      }
      &:focus, &:active {
        outline: none;
      }
    }
    input {
      width: 100%;
      display: block;
      margin: .2em auto;
      outline: none;
      padding: .7em;
      border: 1px solid rgba(0, 0, 0, 0.5);
      font-family: Poppins;
      &:focus {
        outline: none;
      }
      &:first-child {
        margin-top: -2em;
      }
    }
    textarea {
      display: block;
      border: 1px solid rgba(0, 0, 0, 0.5);
      margin: 0 auto;
      margin-bottom: 2em;
      width: 100%;
      height: 15rem;
      outline: none;
      padding: .7em;
      text-align: left;
      line-height: 1.5em;
      resize: none;
      font-family: Poppins;
    }
  }  