@import '../../_variables.scss';

@keyframes fadeOut {
    0% {opacity: 1;}
    100% {opacity: 0;}
}
@keyframes fadeIn {
    0% {opacity: 0;}
    100% {opacity: 1;}
}

.active {
    background-color: $main;
    color: white;
}

nav {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    vertical-align: middle;
    height: 40px;
    padding: .3em;
    position: fixed;
    top: 0;
    background: $third;

    animation-timing-function: linear;
    animation-duration: .2s;
    animation-iteration-count: 1;
    animation-direction: normal;
    animation-fill-mode: forwards;
    z-index: 99;
    
    &:hover {
        animation-name: '';
    }

    ul {
        li {
            float: left;
            list-style-type: none;
        }
        button {
            border: none;
            background-color: inherit;
            text-align: center;
            font-family: Poppins;
            font-size: 100%;
            text-decoration: none;
            color: $secondary;
            margin: 0 2em;
            padding: .9em;
            &:hover {
                cursor: pointer;
            }
            &:active, &:focus {
                outline: none;
            }
        }
    }
}

@media only screen and (max-width: 600px) {
    nav {
        button {
          margin: 0 .5em !important;
      }
    }
}