@import '../../_variables.scss';

#welcome-section {
    width: 100%;
    padding-top: 5em;
    background: $third;
    box-sizing: border-box;
    overflow: hidden;
    h1, p {
        font-family: Poppins;
        text-align: center;
    }
    h1 {
        font-size: 300%;
        margin-top: 2em;
        color: $secondary;
    }
    .title {
        font-size: 120%;
        color: $secondary;
        font-weight: bold;
    }

    .cactus {
        background-position: center;
        background-size: contain;
        background-repeat: no-repeat;
        background-image: url('../../assets/images/walking.gif');
        width: 400px;
        height: 400px;
        margin: 0 auto;
        margin-top: 4em;
    }

    .social-media {
        width: 170px;
        margin: 0 auto;
        margin-top: 2em;
    }

    .linkedin, .github {
        display: block;
        background-position: center;
        background-size: contain;
        background-repeat: no-repeat;
        width: 50px;
        height: 50px;
        float: left;
        margin: 0 1em;
        transition: all .2s;
        &:hover {
            transform:translateY(-10px);
        }
    }

    .linkedin {
        background-image: url('../../assets/images/linkedin.svg');
    }
    .github {
        background-image: url('../../assets/images/github.svg');
    }
}